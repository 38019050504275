//
// Text
//

.text {
  &-truncate {
    @include text-truncate();
  }

  &-left {
    text-align: left;
  }

  &-center {
    text-align: center;
  }

  &-right {
    text-align: right;
  }

  &-justify {
    text-align: justify;
  }

  @each $color, $value in $theme-colors {
    &-#{$color} {
      color: $value;
    }
  }
}
