//
// Variable
//

// Use

@use "sass:math";

// Color

$white: #fff !default;
$black: #000 !default;

$primary: #f7fbfe !default;
$success: #4ade80 !default;
$info: #38bdf8 !default;
$warning: #fde047 !default;
$danger: #ff6760 !default;
$text: #36393e !default;

$theme-colors: () !default;
$theme-colors: map-merge(
  (
    "primary": $primary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "white": $white,
    "black": $black
  ),
  $theme-colors
);

// Breakpoint

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
) !default;

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints);

// Color contrast

$yiq-text-dark: #000 !default;
$yiq-text-light: #fff !default;
$yiq-contrasted-threshold: 190 !default;

// Z-Index

$zindex-1000: 1000 !default;
$zindex-1010: 1010 !default;
$zindex-1020: 1020 !default;
$zindex-1030: 1030 !default;
$zindex-1040: 1040 !default;
$zindex-1050: 1050 !default;
$zindex-1060: 1060 !default;
$zindex-1070: 1070 !default;
$zindex-1080: 1080 !default;
$zindex-1090: 1090 !default;

// Alert

$alert-font-size: sz(14px) !default;
$alert-font-weight: 400 !default;
$alert-line-height: 1.5 !default;
$alert-padding-x: sz(10px) !default;
$alert-padding-y: sz(15px) !default;
$alert-color: $text !default;
$alert-border-radius: sz(8px) !default;

// Loading

$loading-backdrop-bg: $white !default;
$loading-backdrop-opacity: .5 !default;

$loading-width: sz(25px) !default;
$loading-height: $loading-width !default;
$loading-border-width: sz(1px) !default;

$loading-width-lg: sz(40px) !default;
$loading-height-lg: $loading-width-lg !default;
$loading-border-width-lg: sz(2px) !default;

$loading-width-sm: sz(15px) !default;
$loading-height-sm: $loading-width-sm !default;
$loading-border-width-sm: sz(1px) !default;

// Tables

$table-cell-padding: sz(12px) !default;
$table-cell-padding-sm: sz(4.8px) !default;

$table-color: $black !default;
$table-bg: null !default;
$table-accent-bg: rgba($black, .05) !default;
$table-hover-color: $table-color !default;
$table-hover-bg: rgba($black, .075) !default;
$table-active-bg: $table-hover-bg !default;

$table-border-width: sz(1px) !default;
$table-border-color: #ddd !default;

$table-head-bg: #e0e0e0 !default;
$table-head-color: $black !default;

$table-dark-color: $white !default;
$table-dark-bg: #3c444c !default;
$table-dark-accent-bg: rgba($white, .05) !default;
$table-dark-hover-color: $table-dark-color !default;
$table-dark-hover-bg: rgba($white, .075) !default;
$table-dark-border-color: lighten($table-dark-bg, 7.5%) !default;

$table-striped-order: odd !default;

$table-caption-color: #a3a3a3 !default;

$table-bg-level: -9 !default;
$table-border-level: -6 !default;