//
// Btn
//

.btn {
  &-small {
    height: sz(28px);
    font-size: sz(12px);
    font-weight: 400;
    line-height: sz(18px);
    border-radius: sz(16px);
    padding: sz(5px) sz(8px);

    .btn-icon {
      width: sz(12px);
      height: sz(12px);
      margin-left: sz(10px);
    }
  }

  &-medium {
    height: sz(41px);
    font-size: sz(14px);
    font-weight: 400;
    line-height: sz(21px);
    border-radius: sz(20px);
    padding: sz(10px) sz(12px);

    .btn-icon {
      width: sz(16px);
      height: sz(16px);
      margin-left: sz(10px);
    }
  }

  &-large {
    height: sz(56px);
    font-size: sz(16px);
    font-weight: 400;
    line-height: sz(24px);
    border-radius: sz(12px);
    padding: sz(16px) sz(22px);

    .btn-icon {
      width: sz(24px);
      height: sz(24px);
      margin-left: sz(10px);
    }
  }

  &-primary {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: #fff;
    background-color: #36393e;
    box-shadow: 0 sz(2px) sz(6px) rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
  }

  &-secondary {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: #85c3ff;
    border: sz(2px) solid #85c3ff;
    background-color: #fff;
    box-shadow: 0 sz(2px) sz(6px) rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
  }

  &-danger {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: $danger;
    border: sz(2px) solid $danger;
    background-color: #fff;
    box-shadow: 0 sz(2px) sz(6px) rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
  }

  &.loading {
    font-size: 0;

    &::before {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: transparent;
    }
  }
}
