//
// Layout
//

.page {
  position: relative;
  display: flex;
  height: 100%;
  background-color: #f7fbfe;

  &-nav {
    .nav {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: $zindex-1010;
      transform: translateX(-100%);
      transition: transform 0.3s ease-in-out;

      &.active {
        transform: translateX(0);
      }
    }
  }

  &-article {
    flex: 1;
    display: block;
    overflow: hidden;
  }

  &-header {
    display: block;
    padding: sz(30px) 0 sz(10px);
    margin: 0 sz(20px);
  }

  &-main {
    height: calc(100% - sz(100px));
    border-top: sz(1px) solid #ddd;
    padding: sz(30px) sz(20px) sz(30px);
    margin: sz(20px) 0 0;
    overflow: auto;
  }

  @include media-breakpoint-up('md') {
    &-nav {      
      .nav {
        width: sz(250px);
        transform: translateX(0) !important;
      }
    }

    &-article {
      padding-left: sz(250px);
    }
  }
}
