//
// Editor
//

.editor {
  &-wrap {
    position: relative;
    height: 100%;
  }

  &-content,
  &-viewer {
    height: 100%;
    outline: 0;
    color: $text;
    font-size: sz(14px);
    line-height: 1.7;
    user-select: text;

    &:empty {
      + .editor-placeholder {
        display: block;
      }
    }

    &:focus {
      + .editor-placeholder {
        display: none;
      }
    }

    h1 {
      font-size: sz(30px);
      margin: 0;
      padding: 0;
    }

    h2 {
      font-size: sz(24px);
      margin: 0;
      padding: 0;
    }

    h3 {
      font-size: sz(18px);
      margin: 0;
      padding: 0;
    }

    img {
      display: block;
      width: 100%;
      height: auto;
      margin: 0;
      padding: 0;
    }
  }

  &-placeholder {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    font-size: sz(14px);
    font-weight: 400;
    line-height: 1.7;
    color: #999;
    pointer-events: none;
  }
}
