//
// Form
//

.form {
  &-control {
    display: flex;
    flex-direction: column;

    + .form-control {
      margin-top: sz(24px);
    }

    &-label {
      display: block;
      font-size: sz(14px);
      font-weight: 400;
      line-height: sz(21px);
      color: #85c3ff;
      margin-bottom: sz(4px);
    }

    &-input {
      display: block;
      width: 100%;
      font-size: sz(14px);
      font-weight: 400;
      line-height: sz(21px);
      color: $text;
      border: sz(1px) solid #85c3ff;
      border-radius: sz(12px);
      background-color: #dcecfb;
      padding: sz(18px) sz(12px);

      &::placeholder {
        color: $text;
      }
      
      &-small {
        font-size: sz(12px);
        line-height: sz(18px);
        padding: sz(10px) sz(6px);
      }

      &-medium {
        padding: sz(12px) sz(10px);
      }
    }

    &-file {
      display: block;
      width: 100%;
      border: sz(1px) solid #85c3ff;
      border-radius: sz(12px);
      background-color: #dcecfb;
      padding: sz(18px) sz(12px);

      &-input {
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        visibility: hidden;
      }

      &-text {
        display: block;
        font-size: sz(14px);
        font-weight: 400;
        line-height: sz(21px);
        color: $text;
      }

      &-small {
        padding: sz(10px) sz(6px);

        .form-control-file-text {
          font-size: sz(12px);
          line-height: sz(18px);
        }
      }

      &-medium {
        padding: sz(12px) sz(10px);
      }
    }
  }

  &-btn {
    margin-top: sz(40px);
  }
}
