//
// Alert
//

// Base styles

.alert {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: $alert-border-radius;
  padding: $alert-padding-x $alert-padding-y;
  margin-top: sz(15px);

  &-message {
    flex: 1;
    font-size: $alert-font-size;
    font-weight: $alert-font-weight;
    line-height: $alert-line-height;
    color: $alert-color;
  }

  @each $color, $value in $theme-colors {
    &.alert-#{$color} {
      background-color: $value;

      .alert-message {
        color: #fff;
      }
    }
  }
}
