//
// Card
//

.card {
  position: relative;
  background-color: #fff;
  box-shadow: 0 sz(2px) sz(6px) rgba(0, 0, 0, 0.10);

  &-header {
    display: flex;
    align-items: center;
    padding: sz(20px) sz(30px);
    border-bottom: sz(1px) solid #ddd;

    &-title {
      flex: 1;
      font-size: sz(16px);
      font-weight: 700;
      line-height: sz(24px);
      color: $text;
    }

    &-btns {
      display: flex;
      align-items: center;
    }

    &-btn {
      margin-left: sz(10px);
    }
  }

  &-content {
    padding: sz(30px) sz(30px);
  }

  + .card {
    margin-top: sz(30px);
  }
}