//
// Modal
//

ngx-smart-modal {
  &[customclass*="modal"] {
    .overlay {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      overflow: hidden;

      &.nsm-overlay-open {
        background-color: rgba(54, 57, 62, 0.5);
      }
    }

    .nsm-dialog-btn-close {
      display: none;
    }
  }
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: sz(500px);
  box-sizing: border-box;
  outline: 0;

  &.nsm-dialog-open {
    .modal-content {
      opacity: 1;
    }
  }

  &.nsm-dialog-close {
    .modal-content {
      opacity: 0;
    }
  }

  &-content {
    position: relative;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 sz(1px) sz(1px) rgba(100, 116, 139, 0.06), 0 sz(1px) sz(2px) rgba(100, 116, 139, 0.1);
    opacity: 0;
    transition: opacity 300ms ease-in-out;
  }

  &-header {
    display: flex;
    align-items: center;
    border-bottom: sz(1px) solid #ddd;;
    padding: sz(20px);

    &-title {
      flex: 1;
      font-size: sz(14px);
      font-weight: 700;
      line-height: sz(24px);
      color: #25282b;
    }

    &-close {
      position: relative;
      display: block;
      width: sz(30px);
      height: sz(30px);
      font-size: 0;
      border-radius: 50%;
      background-color: #f6f8fb;

      &-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: sz(20px);
        height: sz(20px);
        fill: $text;
      }
    }
  }

  &-main {
    max-height: calc(80vh - sz(150px));
    padding: sz(20px);
    overflow: auto;

    &-text {
      font-size: sz(14px);
      font-weight: 400;
      line-height: sz(24px);
      text-align: center;
    }
  }

  &-footer {
    display: flex;
    justify-content: center;
    border-top: sz(1px) solid #ddd;
    padding: sz(20px);

    &-btn {
      max-width: sz(300px);
      box-shadow: none;
    }
  }
}
