//
// Table
//

//
// Mixins
//

@mixin table-row-variant($state, $background, $border: null) {
  .table-#{$state} {
    &,
    > th,
    > td {
      background-color: $background;
    }

    @if $border != null {
      th,
      td,
      thead th,
      tbody + tbody {
        border-color: $border;
      }
    }
  }

  .table-hover {
    $hover-background: darken(#ddd, 5%);

    .table-#{$state} {
      &:hover {
        background-color: $hover-background;

        > td,
        > th {
          background-color: $hover-background;
        }
      }
    }
  }
}

// Basic Bootstrap table

.table-container {
  width: 100%;
  overflow-x: auto;
}

.table {
  width: 100%;
  min-width: sz(600px);
  color: $table-color;
  background-color: $table-bg;

  th,
  td {
    font-size: sz(14px);
    font-weight: 400;
    line-height: sz(20px);
    vertical-align: middle;
    padding: $table-cell-padding;
    border-top: $table-border-width solid $table-border-color;
    border-bottom: $table-border-width solid $table-border-color;

    &.active {
      color: #85c3ff;
    }
  }

  thead th {
    vertical-align: bottom;
    border-bottom: (2 * $table-border-width) solid $table-border-color;
  }

  tbody + tbody {
    border-top: (2 * $table-border-width) solid $table-border-color;
  }
}

// Condensed table w/ half padding

.table-sm {
  th,
  td {
    padding: $table-cell-padding-sm;
  }
}

// Border versions

.table-bordered {
  border: $table-border-width solid $table-border-color;

  th,
  td {
    border: $table-border-width solid $table-border-color;
  }

  thead {
    th,
    td {
      border-bottom-width: 2 * $table-border-width;
    }
  }
}

.table-borderless {
  th,
  td,
  thead th,
  tbody + tbody {
    border: 0;
  }
}

// Zebra-striping

.table-striped {
  tbody tr:nth-of-type(#{$table-striped-order}) {
    background-color: $table-accent-bg;
  }
}

// Hover effect

.table-hover {
  tbody tr {
    &:hover {
      color: $table-hover-color;
      background-color: $table-hover-bg;
    }
  }
}

// Fixed

.table-fixed {
  table-layout: fixed;
}

// Table backgrounds

@each $color, $value in $theme-colors {
  @include table-row-variant($color, theme-color-level($color, $table-bg-level), theme-color-level($color, $table-border-level));
}

@include table-row-variant(active, $table-active-bg);
