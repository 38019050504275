//
// Toast
//

// Import scss

@import 'ngx-toastr/toastr';

// Basic

.toast-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .ngx-toastr {
    width: auto !important;
    width: 90%;
    max-width: sz(500px);
    margin: sz(30px) auto 0;
  }

  .toast-success,
  .toast-error,
  .toast-info,
  .toast-warning {
    background-image: none;
    border-radius: sz(30px);
    padding: sz(12px) sz(20px);
    box-shadow: 0 sz(1px) sz(20px) rgba(0, 0, 0, 0.2);
  }

  .toast-message {
    font-size: sz(14px);
    font-weight: 500;
    line-height: sz(24px);
  }
  
  .toast-success {
    background-color: $success;
  }

  .toast-error {
    background-color: $danger;
  }

  .toast-info {
    background-color: $info;
  }

  .toast-warning {
    background-color: $warning;
  }
}