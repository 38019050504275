//
// Mixin
//

// Use

@use "sass:math";

// Text truncate

@mixin text-truncate() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin text-truncate-multiple($num) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $num;
  -webkit-box-orient: vertical;
}

// Fluid Size

@mixin fluid($type, $min, $max, $minBreakpoint, $maxBreakpoint) {
  $maxLessOne: $maxBreakpoint - 1;
  $avg: math.div($max + $min, 2);
 
  #{$type}: #{$min}px;
  
  @media (min-width: #{$minBreakpoint}px) and (max-width: #{$maxLessOne}px) {
    #{$type}: #{$avg}px;
    #{$type}: calc(#{$min}px + (#{$max} - #{$min}) * (100vw - #{$minBreakpoint}px) / (#{$maxBreakpoint} - #{$minBreakpoint}))
  }
  
  @media (min-width: #{$maxBreakpoint}px) {
    #{$type}: #{$max}px;
  }
}
