//
// Font
//

@font-face {
  font-family: "Noto Sans KR";
  font-weight: 300;
  font-style: normal;
  src: url("/assets/font/NotoSansKR-DemiLight.eot");
  src:
    local(※),
    url("/assets/font/NotoSansKR-DemiLight.woff2") format("woff2"),
    url("/assets/font/NotoSansKR-DemiLight.woff") format("woff"),
    url("/assets/font/NotoSansKR-DemiLight.otf") format("opentype");
}

@font-face {
  font-family: "Noto Sans KR";
  font-weight: 400;
  font-style: normal;
  src: url("/assets/font/NotoSansKR-Regular.eot");
  src:
    local(※),
    url("/assets/font/NotoSansKR-Regular.woff2") format("woff2"),
    url("/assets/font/NotoSansKR-Regular.woff") format("woff"),
    url("/assets/font/NotoSansKR-Regular.otf") format("opentype");
}

@font-face {
  font-family: "Noto Sans KR";
  font-weight: 500;
  font-style: normal;
  src: url("/assets/font/NotoSansKR-Medium.eot");
  src:
    local(※),
    url("/assets/font/NotoSansKR-Medium.woff2") format("woff2"),
    url("/assets/font/NotoSansKR-Medium.woff") format("woff"),
    url("/assets/font/NotoSansKR-Medium.otf") format("opentype");
}

@font-face {
  font-family: "Noto Sans KR";
  font-weight: 700;
  font-style: normal;
  src: url("/assets/font/NotoSansKR-Bold.eot");
  src:
    local(※),
    url("/assets/font/NotoSansKR-Bold.woff2") format("woff2"),
    url("/assets/font/NotoSansKR-Bold.woff") format("woff"),
    url("/assets/font/NotoSansKR-Bold.otf") format("opentype");
}
