//
// Loading
//

.loading {
  position: relative;

  &.none {
    display: none;
  }

  &-fixed {
    &::before {
      position: fixed;
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: $zindex-1070;
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    animation: loading 500ms infinite linear;
    border: $loading-border-width solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    width: $loading-width;
    height: $loading-height;
    z-index: $zindex-1080;
  }

  &.loading-block {
    height: sz(200px);
  }

  &.loading-lg {
    &::after {
      width: $loading-width-lg;
      height: $loading-height-lg;
      border-width: $loading-border-width-lg;
    }
  }

  &.loading-sm {
    &::after {
      width: $loading-width-sm;
      height: $loading-height-sm;
      border-width: $loading-border-width-sm;
    }
  }

  &.loading-white {
    &::after {
      border-color: #fff;
      border-right-color: transparent;
    }
  }
}

@keyframes loading {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
